@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

.about {
  &__image {
    width: getRem(214);
    height: getRem(122);
    margin: getRem(56) auto;
  }
}
