.container {
  padding-left: var(--side-gap);
  padding-right: var(--side-gap);

  @include media(tablet) {
    font-size: getRem(14);
  }
}

.wrapper {
  max-width: getRem(1360);
  margin-left: auto;
  margin-right: auto;
}

.narrow {
  flex-grow: 1;
  max-width: getRem(898);
  margin: auto;
}

.fill-screen {
  display: flex;
  min-height: calc(100vh - var(--header-height));

  &:not(section):first-of-type {
    min-height: calc(100% - var(--header-height));
    margin-top: var(--header-height);
  }

  & > * {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-bottom: var(--side-gap);
  }
}

.section {
  scroll-margin-top: calc(var(--header-height) + #{getRem(40)});
  margin-top: getRem(80);
  margin-bottom: getRem(120);

  @include media(phone) {
    scroll-margin-top: calc(var(--header-height) + #{getRem(20)});
    margin-top: getRem(72);
    margin-bottom: getRem(80);
  }
}

.section-padding {
  scroll-margin-top: var(--header-height);
  padding: getRem(80) getRem(68);

  @include media(phone) {
    padding: getRem(40) getRem(24);
  }
}

.section-title {
  max-width: getEm(438, 48);
  margin: 0 auto getRem(80);
  text-align: center;

  @include media(tablet) {
    margin-bottom: getRem(24);
  }
}
