/**
 * Зная ширину элемента в px и ширину его родителя в px, 
 * получаем значение ширины элемента в процентах.
 * Пример использования: getPercent(200, 1000);
 * Где,
 * $current = размер в пикселях у элемента
 * $parent = размер родительского блока в пикселях
 */
@function getPercent($current, $parent) {
  @return calc($current / $parent * 100%);
}

/**
 * Переводим из пикселей в em
 * Пример использования: getEm(16, 18);
 * Где,
 * $current = размер в пикселях, который надо перевести
 * $parent = базовый размер родительского блока в пикселях
 */
@function getEm($current, $parent) {
  @return calc($current / $parent) + em;
}

/**
 * Переводим из пикселей в em
 * Пример использования: getRem(20);
 * Где,
 * $current = размер в пикселях, который надо перевести
 */
@function getRem($current) {
  @return calc($current / $baseFontSize) + rem;
}
