@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

.contact {
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  padding-bottom: calc(#{getRem(386)});
  padding-left: var(--side-gap);
  padding-right: var(--side-gap);
  margin-left: calc(-1 * var(--side-gap));
  margin-right: calc(-1 * var(--side-gap));

  @include media(tablet) {
    padding-bottom: getRem(290);
  }

  &__cube {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &:nth-of-type(1) {
      bottom: getRem(204);
      width: getRem(98);
      margin-left: -16%;

      @include media(tablet) {
        bottom: getRem(120);
        width: getRem(62);
        margin-left: 0;
      }
    }

    &:nth-of-type(2) {
      bottom: getRem(-98);
      width: getRem(326);
      margin-left: -29%;

      @include media(tablet) {
        bottom: getRem(40);
        width: getRem(126);
        margin-left: 39%;
      }
    }

    &:nth-of-type(3) {
      bottom: getRem(-98);
      width: getRem(418);
      margin-left: 27.5%;

      @include media(tablet) {
        bottom: getRem(-68);
        width: getRem(192);
        margin-left: -33%;
      }
    }
  }
}

.form {
  max-width: getRem(438);
  margin: 0 auto;

  @include media(phone) {
    padding-left: getRem(8);
    padding-right: getRem(8);
  }

  &__item {
    margin-bottom: getRem(32);

    @include media(phone) {
      margin-bottom: getRem(24);
    }
  }

  &__input,
  &__textarea {
    appearance: none;
    width: 100%;
    padding: getRem(16);
    font-size: getRem(16);
    line-height: 1.25;
    color: $mainTextColor;
    background-color: $fillsColor;
    border: 0;
    border-radius: 0;
    transition-property: background-color;
    transition-duration: 0.25s;

    &::placeholder {
      color: rgba($mainTextColor, 0.5);
      opacity: 1;
    }

    &:hover {
      background-color: darken($fillsColor, 2.5%);
    }

    &:focus {
      outline: 0;
      background-color: darken($fillsColor, 5%);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $mainTextColor;
      box-shadow: 0 0 0px 1000px $dimMainColor inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &__textarea {
    min-height: getRem(145);
    resize: vertical;
  }

  &__footer {
    margin-top: getRem(56);
    text-align: center;

    @include media(phone) {
      margin-top: getRem(40);
    }
  }

  &__status {
    margin-top: getRem(20);
    text-align: center;
  }

  &__success {
    color: $mainColor;
  }

  &__error {
    color: $amazonColor;
  }
}
