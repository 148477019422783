@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

.hero {
  background-color: $secondaryColor;

  &__wrapper {
    display: flex;
    align-items: center;
    width: getPercent(1012, 1224);
    min-width: 0;
    gap: getRem(58) getRem(28);
    font-size: getRem(18);
    line-height: 1.33;

    @include media(laptop) {
      width: 100%;
    }

    @include media(tablet) {
      flex-direction: column;
      max-width: getRem(650);
      text-align: center;
    }

    @include media(phone) {
      max-width: getRem(550);
      gap: getRem(25);
      font-size: getRem(14);
    }
  }

  &__content {
    flex-grow: 1;
    max-width: 100%;
  }

  &__image {
    flex-shrink: 0;
    max-width: getRem(409);
    width: getPercent(409, 1028);

    @include media(laptop) {
      width: getPercent(300, 822);
    }

    @include media(tablet) {
      width: getPercent(289, 618);
    }

    @include media(phone) {
      max-width: getRem(260);
      width: getPercent(136, 296);
    }
  }

  &__text {
    min-width: 0;
  }

  &__title {
    margin-top: getRem(56);
    margin-bottom: 0;

    @include media(tablet) {
      margin-top: getRem(32);
    }

    @media (max-width: 350px) {
      font-size: getRem(32);
    }

    &-mark {
      position: relative;
      z-index: 1;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: getEm(-8, 64);
        right: getEm(-8, 64);
        bottom: 0;
        height: getEm(24, 64);
        background-color: $lightColor;
      }
    }
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: getRem(8);

    @include media(phone) {
      margin-bottom: getRem(4);
    }
  }

  &__paragraph {
    @include media(tablet) {
      max-width: getRem(292);
      margin-left: auto;
      margin-right: auto;
    }

    @include media(phone) {
      max-width: getRem(224);
    }
  }
}
