@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

.nodes {
  position: relative;
  min-height: getRem(600);
  background-color: $lightColor;
  background-image: url("../../images/cube.svg");
  background-repeat: no-repeat;
  background-position: bottom getRem(-210) left getRem(-153);

  @include media(tablet) {
    min-height: auto;
    background-size: getRem(320) auto;
    background-position: bottom getRem(32) left getRem(-118);
  }

  &__title {
    max-width: getRem(668);
    margin: 0 auto;
  }

  &__image {
    position: absolute;
    left: 75.7%;
    top: calc(50% - #{getRem(222)});
    width: getRem(122);
    height: getRem(183);

    @include media(laptop) {
      top: calc(50% - #{getRem(240)});
    }

    @include media(tablet) {
      position: static;
      margin: 0 auto getRem(56);
      transform: translateX(getRem(-19));
    }

    @include media(phone) {
      position: static;
      width: getRem(80);
      height: getRem(120);
      height: auto;
      transform: translateX(getRem(-13));
    }
  }
}
