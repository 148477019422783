@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

.team {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: getRem(24);

    @include media(tablet) {
      grid-template-columns: 1fr;
    }

    @include media(phone) {
      gap: getRem(8);
    }
  }

  &__member {
    padding: getPercent(80, 668);
    background-color: $lightColor;

    &:first-of-type {
      grid-row: span 2;
    }

    @include media(phone) {
      padding: getRem(80) getRem(24);

      &:first-of-type {
        grid-row: auto;
      }
    }
  }

  &__member-figure {
    display: flex;
    align-items: center;
    gap: getRem(33) getRem(40);
    margin: 0 0 getRem(24);

    @include media(phone) {
      flex-direction: column;
      justify-content: center;
      gap: getRem(25);
      text-align: center;
    }
  }

  &__member--main &__member-figure {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &__member-link {
    @include link-reset;
    flex-shrink: 0;
    position: relative;
    margin-bottom: getRem(17);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: getRem(-17);
      width: getRem(32);
      height: getRem(32);
      margin: 0 auto;
      background-color: $mainBgColor;
      background-image: url("../../images/linkedin.svg");
      background-position: 50% 50%;
      background-size: getPercent(16, 32) getPercent(16, 32);
      background-repeat: no-repeat;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 40, 40, 0);
      transition: box-shadow 0.25s;
    }

    &:hover {
      &::after {
        box-shadow: 0 getRem(4) getRem(16) getRem(-2) rgba(0, 40, 40, 0.15);
      }
    }

    &:active {
      &::after {
        box-shadow: 0 0 getRem(2) 0 rgba(0, 40, 40, 0.1);
      }
    }
  }

  &__member-image {
    width: getRem(80);
    height: getRem(80);
    border-radius: 50%;

    @include media(phone) {
      width: getRem(80) !important;
      height: getRem(80) !important;
    }
  }

  &__member--main &__member-image {
    width: getRem(120);
    height: getRem(120);
  }

  &__member-name {
    margin: 0 0 getRem(8);
  }

  &__member-job {
    max-width: getRem(218);
    margin: 0;
    color: $grayColor;
    font-size: getRem(14);
    line-height: 1.571;
  }

  &__member--main &__member-job {
    margin-left: auto;
    margin-right: auto;
  }

  &__member-description {
    position: relative;
    z-index: 1;
    padding-top: getRem(40);
    padding-right: getRem(40);

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      right: 0;
      top: 0;
      width: getRem(64);
      height: getRem(64);
      background-color: $dimMainColor;
      background-image: url("../../images/quote.svg");
      background-position: 50% 50%;
      background-size: getPercent(24, 64) getPercent(24, 64);
      background-repeat: no-repeat;
      border-radius: 50%;
    }

    @include media(phone) {
      padding-left: getRem(40);
      padding-right: 0;
      padding-top: getRem(12);

      &::before {
        left: 0;
        right: auto;
        width: getRem(48);
        height: getRem(48);
      }
    }
  }

  &__member--main &__member-description {
    padding-left: getRem(40);
    padding-right: 0;

    &::before {
      left: 0;
      right: auto;
    }
  }
}
