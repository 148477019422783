:root {
  --header-height: #{getRem(92)};
  --side-gap: #{getRem(40)};

  @include media(tablet) {
    --header-height: #{getRem(114)};
  }

  @include media(phone) {
    --header-height: #{getRem(90)};
  }

  @include media(phone) {
    --side-gap: #{getRem(8)};
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  color: $mainTextColor;
  background-color: $dimMainColor;
}

html,
body,
main {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  min-width: 320px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $mainFont;
  line-height: 1.75;
  color: $mainTextColor;
  background-color: $mainBgColor;
}

svg {
  vertical-align: middle;
  fill: currentColor;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

p {
  margin-top: 0;
  margin-bottom: 0;

  & + & {
    margin-top: getRem(15);
  }
}

p,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  overflow-wrap: break-word;
}

h1,
.h1 {
  font-family: $headingFont;
  font-size: getRem(64);
  line-height: 1.125;

  @include media(phone) {
    font-size: getRem(36);
  }
}

h2,
.h2 {
  font-family: $headingFont;
  font-size: getRem(48);
  line-height: 1.166;

  @include media(phone) {
    font-size: getRem(32);
  }
}

h3,
.h3 {
  font-weight: 700;
  font-size: getRem(32);
  line-height: 1.375;

  @include media(phone) {
    font-size: getRem(20);
  }
}

h4,
.h4 {
  font-weight: 400;
  font-size: getRem(32);
  line-height: 1.375;

  @include media(phone) {
    font-size: getRem(24);
  }
}

.text-center {
  text-align: center;
}

[hidden] {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.reset-margin {
  margin-top: 0;
  margin-bottom: 0;
}
