html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.container {
  padding-left: var(--side-gap);
  padding-right: var(--side-gap);
}

.wrapper {
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
}

.narrow {
  max-width: 56.125rem;
  flex-grow: 1;
  margin: auto;
}

.fill-screen {
  min-height: calc(100vh - var(--header-height));
  display: flex;
}

.fill-screen:not(section):first-of-type {
  min-height: calc(100% - var(--header-height));
  margin-top: var(--header-height);
}

.fill-screen > * {
  margin-bottom: var(--side-gap);
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.section {
  scroll-margin-top: calc(var(--header-height)  + 2.5rem);
  margin-top: 5rem;
  margin-bottom: 7.5rem;
}

.section-padding {
  scroll-margin-top: var(--header-height);
  padding: 5rem 4.25rem;
}

.section-title {
  max-width: 9.125em;
  text-align: center;
  margin: 0 auto 5rem;
}

:root {
  --header-height: 5.75rem;
  --side-gap: 2.5rem;
}

*, :before, :after {
  box-sizing: border-box;
}

::selection {
  color: #002828;
  background-color: #cfeeee;
}

html, body, main {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  min-width: 320px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #002828;
  background-color: #fff;
  font-family: Noto Sans, sans-serif;
  line-height: 1.75;
}

svg {
  vertical-align: middle;
  fill: currentColor;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

p + p {
  margin-top: .9375rem;
}

p, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  overflow-wrap: break-word;
}

h1, .h1 {
  font-family: Lora, serif;
  font-size: 4rem;
  line-height: 1.125;
}

h2, .h2 {
  font-family: Lora, serif;
  font-size: 3rem;
  line-height: 1.166;
}

h3, .h3 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.375;
}

h4, .h4 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.375;
}

.text-center {
  text-align: center;
}

[hidden] {
  display: none !important;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.reset-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.button {
  color: inherit;
  appearance: none;
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background-color: teal;
  border: 0;
  border-radius: 1.375rem;
  padding: 0 1.5rem;
  font-weight: 400;
  text-decoration: none;
  transition: color .25s, background-color .25s, border-color .25s;
  display: inline-block;
  position: relative;
}

.button:focus {
  outline: none;
}

.button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid teal;
}

.button:focus {
  outline: none;
}

.button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid teal;
}

.button::-moz-focus-inner {
  border: 0 !important;
}

.button:hover, .button--loading {
  background-color: #019292;
}

.button--loading {
  cursor: wait;
}

.button:after {
  content: "";
  vertical-align: middle;
  width: 0;
  height: 100%;
  display: inline-block;
}

.button:active {
  background-color: #007979;
}

.button__text {
  transition-property: opacity, visibility;
  transition-duration: .3s;
}

.button--loading .button__text, .button:before {
  opacity: 0;
  visibility: hidden;
}

.button:before {
  content: "";
  height: 70%;
  aspect-ratio: 1 / 1;
  border: .25rem solid;
  border-left-color: #0000;
  border-radius: 50%;
  margin: auto;
  transition-property: opacity, visibility;
  transition-duration: .3s;
  position: absolute;
  inset: 0;
}

.button--loading:before {
  opacity: 1;
  visibility: visible;
  animation: 1.5s linear infinite preloader;
}

.button--big {
  height: 3.5rem !important;
  border-radius: 1.75rem !important;
  padding: 0 3.125rem !important;
  font-size: 1.125rem !important;
}

@keyframes preloader {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .container {
    font-size: .875rem;
  }

  .section-title {
    margin-bottom: 1.5rem;
  }

  :root {
    --header-height: 7.125rem;
  }

  .button {
    height: 2.125rem;
    padding: 0 1rem;
  }
}

@media (max-width: 736px) {
  .section {
    scroll-margin-top: calc(var(--header-height)  + 1.25rem);
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  .section-padding {
    padding: 2.5rem 1.5rem;
  }

  :root {
    --header-height: 5.625rem;
    --side-gap: .5rem;
  }

  h1, .h1 {
    font-size: 2.25rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.25rem;
  }

  h4, .h4 {
    font-size: 1.5rem;
  }
}

.header {
  z-index: 100;
  height: var(--header-height);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fffc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__wrapper {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
}

.header__navigation-list {
  align-items: baseline;
  gap: 2.5rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.header__navigation-link {
  color: inherit;
  text-decoration: none;
  transition: color .25s;
}

.header__navigation-link:focus {
  outline: none;
}

.header__navigation-link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid teal;
}

.header__navigation-link:hover {
  color: teal;
}

.header__navigation-link:active {
  color: #007979;
}

.header__logo {
  width: 6.875rem;
}

@media (max-width: 1024px) {
  .header__wrapper {
    flex-direction: column;
  }

  .header__logo {
    width: 3.875rem;
  }
}

@media (max-width: 736px) {
  .header__wrapper {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }

  .header__navigation {
    align-self: stretch;
  }

  .header__navigation-list {
    width: 100%;
    max-width: 20.5rem;
    justify-content: space-between;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.hero {
  background-color: #ffe568;
}

.hero__wrapper {
  width: 82.6797%;
  min-width: 0;
  align-items: center;
  gap: 3.625rem 1.75rem;
  font-size: 1.125rem;
  line-height: 1.33;
  display: flex;
}

.hero__content {
  max-width: 100%;
  flex-grow: 1;
}

.hero__image {
  max-width: 25.5625rem;
  width: 39.786%;
  flex-shrink: 0;
}

.hero__text {
  min-width: 0;
}

.hero__title {
  margin-top: 3.5rem;
  margin-bottom: 0;
}

.hero__title-mark {
  z-index: 1;
  display: inline-block;
  position: relative;
}

.hero__title-mark:before {
  content: "";
  z-index: -1;
  height: .375em;
  background-color: #f7f9f8;
  position: absolute;
  bottom: 0;
  left: -.125em;
  right: -.125em;
}

.hero__subtitle {
  margin-top: 0;
  margin-bottom: .5rem;
}

@media (max-width: 1320px) {
  .hero__wrapper {
    width: 100%;
  }

  .hero__image {
    width: 36.4964%;
  }
}

@media (max-width: 1024px) {
  .hero__wrapper {
    max-width: 40.625rem;
    text-align: center;
    flex-direction: column;
  }

  .hero__image {
    width: 46.7638%;
  }

  .hero__title {
    margin-top: 2rem;
  }

  .hero__paragraph {
    max-width: 18.25rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 736px) {
  .hero__wrapper {
    max-width: 34.375rem;
    gap: 1.5625rem;
    font-size: .875rem;
  }

  .hero__image {
    max-width: 16.25rem;
    width: 45.9459%;
  }

  .hero__subtitle {
    margin-bottom: .25rem;
  }

  .hero__paragraph {
    max-width: 14rem;
  }
}

@media (max-width: 350px) {
  .hero__title {
    font-size: 2rem;
  }
}

.about__image {
  width: 13.375rem;
  height: 7.625rem;
  margin: 3.5rem auto;
}

.nodes {
  min-height: 37.5rem;
  background-color: #f7f9f8;
  background-image: url("images/cube.0b7182b2.svg");
  background-position: left -9.5625rem bottom -13.125rem;
  background-repeat: no-repeat;
  position: relative;
}

.nodes__title {
  max-width: 41.75rem;
  margin: 0 auto;
}

.nodes__image {
  width: 7.625rem;
  height: 11.4375rem;
  position: absolute;
  top: calc(50% - 13.875rem);
  left: 75.7%;
}

@media (max-width: 1024px) {
  .nodes {
    min-height: auto;
    background-position: left -7.375rem bottom 2rem;
    background-size: 20rem;
  }

  .nodes__image {
    margin: 0 auto 3.5rem;
    position: static;
    transform: translateX(-1.1875rem);
  }
}

@media (max-width: 1320px) {
  .nodes__image {
    top: calc(50% - 15rem);
  }
}

@media (max-width: 736px) {
  .nodes__image {
    width: 5rem;
    height: 7.5rem;
    height: auto;
    position: static;
    transform: translateX(-.8125rem);
  }
}

.team__grid {
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  display: grid;
}

.team__member {
  background-color: #f7f9f8;
  padding: 11.976%;
}

.team__member:first-of-type {
  grid-row: span 2;
}

.team__member-figure {
  align-items: center;
  gap: 2.0625rem 2.5rem;
  margin: 0 0 1.5rem;
  display: flex;
}

.team__member--main .team__member-figure {
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.team__member-link {
  color: inherit;
  flex-shrink: 0;
  margin-bottom: 1.0625rem;
  text-decoration: none;
  transition: color .25s;
  position: relative;
}

.team__member-link:focus {
  outline: none;
}

.team__member-link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid teal;
}

.team__member-link:after {
  content: "";
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  background-image: url("images/linkedin.7a52509d.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  border-radius: 50%;
  margin: 0 auto;
  transition: box-shadow .25s;
  position: absolute;
  bottom: -1.0625rem;
  left: 0;
  right: 0;
  box-shadow: 0 0 #00282800;
}

.team__member-link:hover:after {
  box-shadow: 0 .25rem 1rem -.125rem #00282826;
}

.team__member-link:active:after {
  box-shadow: 0 0 .125rem #0028281a;
}

.team__member-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.team__member--main .team__member-image {
  width: 7.5rem;
  height: 7.5rem;
}

.team__member-name {
  margin: 0 0 .5rem;
}

.team__member-job {
  max-width: 13.625rem;
  color: #708b83;
  margin: 0;
  font-size: .875rem;
  line-height: 1.571;
}

.team__member--main .team__member-job {
  margin-left: auto;
  margin-right: auto;
}

.team__member-description {
  z-index: 1;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  position: relative;
}

.team__member-description:before {
  content: "";
  z-index: -1;
  width: 4rem;
  height: 4rem;
  background-color: #cfeeee;
  background-image: url("images/quote.75dfd2b6.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 37.5% 37.5%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.team__member--main .team__member-description {
  padding-left: 2.5rem;
  padding-right: 0;
}

.team__member--main .team__member-description:before {
  left: 0;
  right: auto;
}

@media (max-width: 1024px) {
  .team__grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 736px) {
  .team__grid {
    gap: .5rem;
  }

  .team__member {
    padding: 5rem 1.5rem;
  }

  .team__member:first-of-type {
    grid-row: auto;
  }

  .team__member-figure {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 1.5625rem;
  }

  .team__member-image {
    width: 5rem !important;
    height: 5rem !important;
  }

  .team__member-description {
    padding-top: .75rem;
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .team__member-description:before {
    width: 3rem;
    height: 3rem;
    left: 0;
    right: auto;
  }
}

.contact {
  padding-bottom: 24.125rem;
  padding-left: var(--side-gap);
  padding-right: var(--side-gap);
  margin-bottom: 0;
  margin-left: calc(-1 * var(--side-gap));
  margin-right: calc(-1 * var(--side-gap));
  position: relative;
  overflow: hidden;
}

.contact__cube {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.contact__cube:nth-of-type(1) {
  width: 6.125rem;
  margin-left: -16%;
  bottom: 12.75rem;
}

.contact__cube:nth-of-type(2) {
  width: 20.375rem;
  margin-left: -29%;
  bottom: -6.125rem;
}

.contact__cube:nth-of-type(3) {
  width: 26.125rem;
  margin-left: 27.5%;
  bottom: -6.125rem;
}

.form {
  max-width: 27.375rem;
  margin: 0 auto;
}

.form__item {
  margin-bottom: 2rem;
}

.form__input, .form__textarea {
  appearance: none;
  width: 100%;
  color: #002828;
  background-color: #f3f3f0;
  border: 0;
  border-radius: 0;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.25;
  transition-property: background-color;
  transition-duration: .25s;
}

.form__input::placeholder, .form__textarea::placeholder {
  color: #00282880;
  opacity: 1;
}

.form__input:hover, .form__textarea:hover {
  background-color: #edede9;
}

.form__input:focus, .form__textarea:focus {
  background-color: #e8e8e2;
  outline: 0;
}

.form__input:-webkit-autofill, .form__input:-webkit-autofill:hover, .form__input:-webkit-autofill:focus, .form__textarea:-webkit-autofill, .form__textarea:-webkit-autofill:hover, .form__textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #002828;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #cfeeee;
}

.form__textarea {
  min-height: 9.0625rem;
  resize: vertical;
}

.form__footer {
  text-align: center;
  margin-top: 3.5rem;
}

.form__status {
  text-align: center;
  margin-top: 1.25rem;
}

.form__success {
  color: teal;
}

.form__error {
  color: #f90;
}

@media (max-width: 1024px) {
  .contact {
    padding-bottom: 18.125rem;
  }

  .contact__cube:nth-of-type(1) {
    width: 3.875rem;
    margin-left: 0;
    bottom: 7.5rem;
  }

  .contact__cube:nth-of-type(2) {
    width: 7.875rem;
    margin-left: 39%;
    bottom: 2.5rem;
  }

  .contact__cube:nth-of-type(3) {
    width: 12rem;
    margin-left: -33%;
    bottom: -4.25rem;
  }
}

@media (max-width: 736px) {
  .form {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .form__item {
    margin-bottom: 1.5rem;
  }

  .form__footer {
    margin-top: 2.5rem;
  }
}

/*# sourceMappingURL=index.fda755b1.css.map */
