.button {
  @include link-reset;
  @include button-reset;

  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: getRem(44);
  padding: 0 getRem(24);
  text-align: center;
  font-weight: 400;
  color: $buttonTextColor;
  background-color: $buttonBgColor;
  border-radius: getRem(22);

  @include media(tablet) {
    height: getRem(34);
    padding: 0 getRem(16);
  }

  &:hover,
  &--loading {
    background-color: $buttonBgHoverColor;
  }

  &--loading {
    cursor: wait;
  }

  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
  }

  &:active {
    background-color: $buttonBgActiveColor;
  }

  &__text {
    transition-property: opacity, visibility;
    transition-duration: 0.3s;
  }

  &--loading &__text,
  &::before {
    opacity: 0;
    visibility: hidden;
  }

  &::before {
    @include absolute-fill;

    content: "";
    height: 70%;
    aspect-ratio: 1 / 1;
    margin: auto;
    border: getRem(4) solid;
    border-left-color: transparent;
    border-radius: 50%;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;
  }

  &--loading {
    &::before {
      animation: preloader 1.5s linear infinite;
      opacity: 1;
      visibility: visible;
    }
  }

  &--big {
    height: getRem(56) !important;
    padding: 0 getRem(50) !important;
    font-size: getRem(18) !important;
    border-radius: getRem(28) !important;
  }
}

@keyframes preloader {
  to {
    transform: rotate(360deg);
  }
}
