@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background-color: rgba($mainBgColor, 0.8);
  backdrop-filter: blur(10px);

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: getRem(16);
    padding-top: getRem(24);
    padding-bottom: getRem(24);

    @include media(tablet) {
      flex-direction: column;
    }

    @include media(phone) {
      flex-direction: column;
      padding-top: getRem(16);
      padding-bottom: getRem(8);
    }
  }

  &__navigation {
    @include media(phone) {
      align-self: stretch;
    }
  }

  &__navigation-list {
    display: flex;
    align-items: baseline;
    gap: getRem(40);
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include media(phone) {
      width: 100%;
      max-width: getRem(328);
      gap: getRem(16);
      margin-left: auto;
      margin-right: auto;
      padding-left: getRem(8);
      padding-right: getRem(8);
      justify-content: space-between;
    }
  }

  &__navigation-link {
    @include link-reset;

    &:hover {
      color: $buttonBgColor;
    }

    &:active {
      color: $buttonBgActiveColor;
    }
  }

  &__logo {
    width: getRem(110);

    @include media(tablet) {
      width: getRem(62);
    }
  }
}
