$mainBgColor: #fff;
$mainTextColor: #002828;
$secondaryColor: #ffe568;
$amazonColor: #ff9900;
$buttonTextColor: #fff;
$buttonBgColor: #008080;
$buttonBgHoverColor: #019292;
$buttonBgActiveColor: #007979;
$lightColor: #f7f9f8;
$grayColor: #708b83;
$mainColor: #008080;
$dimMainColor: #cfeeee;
$fillsColor: #f3f3f0;

$headingFont: "Lora", serif;
$mainFont: "Noto Sans", sans-serif;

$baseFontSize: 16;

$laptop: 1320px;
$tablet: 1024px;
$phone: 736px;
